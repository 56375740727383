@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }
 
  100% {
    opacity: 1;
  }
}

body {
  * {
    outline: none;
  }

}

html {
  background-color: #f853bd;
}

.MuiCircularProgress-colorPrimary {
  color: #fff !important;
  width: 30px !important;
  height: 30px !important;
}

.MuiTableCell-head {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.MuiTableCell-root {
  font-size: 14px !important;
  height: 65px;
}

.MuiTab-root {
  line-height: 3 !important;
  font-family: sans-serif !important;
  font-weight: bolder !important;
  text-transform: capitalize !important;
  color: #162638 !important;
  
}

.toggle-wrap {
  background-color: #edf2f7;
  border: 2px solid #edf2f7;
  font-size: .875rem;
}
.toggle-btn {
  background: white;
  border-radius: 9999px;
  font-size: .875rem;
  border: none;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4,0,1,1);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: inline-flex;

}

.toggle-active {
  color: #63b3ed;
  background-color: #fff;
}

.toggle-inactive {
  background-color: none !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #f853bd;
  height: 3px !important;
}

.PrivateTabIndicator-colorSecondary-7 {
  background-color: #f853bd !important;
  height: 3px !important;
}

.Mui-selected {
  color: #f853bd !important;

}

.MuiTab-textColorInherit:hover {
  opacity: 1 !important;
  transition: all 300ms;
}


// .main-content {
//   animation: slideIn .2s;
//   width: 100%;

// }

.MuiPaper-root {
  border-radius: 8px;
}



.campaign-title h6 {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
}

.hide-rightbar {
  right: -290px !important;
  
}

select.inputField {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQwOTk3IDQuNjY5OTlMMi40Nzk5NyAzLjE3OTk5TDMuNTM5OTcgNC42Njk5OUgxLjQwOTk3WiIgZmlsbD0iIzAwMDgzMCIvPgo8cGF0aCBkPSJNMy41Mzk5NyA1LjMyOTk5TDIuNDc5OTcgNi44MTk5OUwxLjQwOTk3IDUuMzI5OTlIMy41Mzk5N1oiIGZpbGw9IiMwMDA4MzAiLz4KPC9zdmc+Cg==) no-repeat;
  background-position:right;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
  background-size: contain;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}
.rangeslider__fill {
  background-color: #556ee6 !important;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  margin: 2em 0;
}
//Crypto enteries
.bs-select select {
  width: auto !important;
}

.apexcharts-canvas {
  text {
    fill: #adb5bd !important;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

.madeGentle {
  font-family: 'made_gentleregular', sans-serif !important;
  letter-spacing: 0.25px;
}

.PoppinsBlack {
  font-family: 'Poppins Black', sans-serif !important;
}

.Poppins {
  font-family: 'Poppins', sans-serif !important;
}
.topLeft {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  margin-right: 4px;
}
.react-international-phone-input-container .react-international-phone-input {
  border: 1px solid rgb(229, 231, 235) !important;
  height: 50px;
}
.react-international-phone-country-selector-button {
  border: 1px solid rgb(229, 231, 235) !important;
  height: 50px;
}
.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .topLeft {
    max-width: 200px;
    margin-right: 2px;
  }
  .topLeftExclusive {
    max-width: 110px;
    margin-right: 2px;
  }
  .bottomRight {
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.mr-2 {
  margin-right: .5rem !important;
}
.mr-3 {
  margin-right: 1rem;
}
.cursor-pointer{
  cursor: pointer;
}
body {
  background-color: #f7f8f9 !important;
}
.splashy-banner {
  display: flex;
  background-color: white;
  flex-direction: row;
  border-radius: 8px;
  margin-bottom: 24px;
}

.left-splashy {
  background-color: rgb(210,234,244);
  width: 30%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button {
  height: 40px;
  width: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
  background-color: rgba(0,0,0,.5);
  border: 1px solid #fff;
  border-radius: 100%;
  margin: auto;
  display: flex;
  align-self: center;
  padding: 6px;
  justify-content: center;
  align-items: center;
  transition: all .1s ease-in-out;
}

.job-box .song-name {
  max-width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.25rem !important;
  white-space: nowrap;
  line-height: 1.4;
}
.songSubMessage {
  font-size: 16px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mr-1 {
  margin-right: .25rem;
}
.alert-gray {
  background-color: #e9ecf2 !important;
}
.play-box {
    background-size: cover;
    height: 80px;
    width: 80px;
    border-radius: 4px;
    outline:0;
    border:none;
    padding:0;
    // margin-top: 18px !important;
}



.play-button-big {
    height: 30px;
    width: 30px;
    box-shadow: rgba(0, 0, 0, .4) 0px 0px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
    border-radius: 100%;
    margin: auto;
    display: flex;
    align-self: center;
    padding: 6px;
    justify-content: center;
    align-items: center;
    transition: all .1s ease-in-out;
}

.play-box-big {
    background-size: cover;
    height: 70px;
    width: 70px;
    border-radius: 8px;
    outline:0;
    border:none;
    padding:0;
}


.overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
}

#playIcon {
    transition: all .1s ease-in-out;

}

.overlay:hover .play-button {
    transform: scale(1.1)
}

@media (min-width: 992px) {
    .hide-desktop {
        display: none !important;
    }
}

@media (max-width: 992px) {
  .mobile-creator-col {
    width: 100px;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
  }
  .iphone {
    margin-left: auto !important;
  }
}

@media (max-width: 992px) {
  .heading-mobile {
    font-size: 2.3rem;
  }
  .table > :not(caption) > * > * {
    padding: 0.75rem 0.3rem;
  }
}

@media (min-width: 992px) {
  .mobile-creator-col {
    width: 165px;
    max-width: 165px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 3px;
  }
  .heading-mobile {
    font-size: 54px;;
  }
}
.MuiTablePagination-selectRoot {
  margin-right: 12px !important;
}
.MuiTablePagination-actions {
  margin-left: 0px !important;
}

.dropdown-menu-end {
  top: 0px !important;
  position: absolute;
}

.dropdown-menu.show {
    top: 35px !important;
    max-width: 250px;
    min-width: 200px;
}

#lp-topbar {
  top: 0;
  right: 0;
  min-height: 300px;
  left: 250px;
  z-index: 1002;
  background-color: #162638;
  background-size: contain;
}

.btn-block-mobile {
  padding: 13px 15px;
}

@media (max-width: 992px) {
  .btn-block-mobile {
    width: 100%;
    margin-left: 0px !important;
  }
  .topnav {
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
  }
  .navbar {
    display: flex;
    justify-content: flex-start;
  }
}


.inputField {
  border: 1px solid rgb(229,231,235) !important;
  padding: 0.47rem 0.75rem;
  border-radius: 6px;
  font-size: 16px;
  height:50px;
  width: 100%;
}

.brandpricing { 
  max-width:600px;
  margin:auto;
  height: 154px;
}

.birthdayInput {
  display: flex;
  flex-direction: row;
  max-width:320px;
}
.featureboxContainer {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.switch-wrapper > div.enter {
  z-index: 1;
}

.switch-wrapper > div.enter-active {
  transition: transform 200ms ease-in;
}

.switch-wrapper > div.exit {
  z-index: 1;
}

.switch-wrapper > div.exit-active {
  transition: transform 200ms ease-in;
}

.ease-in {
  animation: fadeIn .5s;

}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.banner-launch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
    .hide-mobile {
        display: none !important;
    }
    .mt-1-mobile{
      margin-top: .4rem;;
    }
    .actionCard{
      height: 84px;
    }
    .homeImg {
      height: 27px !important;
      margin-bottom: 5px !important;
    }
    .campaignTabImgContainer {
      width: 27px !important;
    }
    .actionCard h6 {
      font-size: .9rem !important;
    }
    .full-width-mobile {
      width: 100%;
    }
    .payments-sort {
      flex-direction: column-reverse !important;
      align-items: flex-start !important;
      margin-top: 0px !important;
    }
    .brandpricing{
      max-width: 330px;
      height: 85px;
    }
    .featurebox {
      margin-bottom: 8px;
    }
    .featureboxContainer {
      display:flex;
      justify-content: center;
      grid-template-columns: 1fr;
      flex-direction: column;
      align-items: center;
    }
    .campaignHeader {
      flex-direction: column;
      align-items: initial !important;
    }
    .campaignSubtitle {
      margin-top: 1rem !important;
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;
    }
    .banner-launch{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .btn {
        width: 100%;
        margin-top: 1rem;
      }
    }
}

.nativeRoundedCard {
  transition: height 0.25s ease-in;
}
.featurebox {
  background-color: #fff !important;
  border-radius: 8px;
  max-width: 370px;
}

.icon-img {
  width: 40px;
  height: auto;
  background-color: #bfc3c9 !important;

}

.coming-soon {
    font-size: 8px;
    padding: 3px;
    border-radius: 4px;
    color: white;
    float: right;
    position: absolute;
    right: 5px;
    top: 5px;
}

.header-profile-user {
  background-size:cover !important;
}
.required {
  background-color: lightgoldenrodyellow !important;
}
.bg-pink {
  background-color: #f853bd !important; }

.activeTab {
  align-items: center;
  background-image: linear-gradient(118deg, #f853bd, rgba(248, 83, 189, 0.7));
  background-size: auto;
  border-radius: 4px;
  margin-right: 0px !important;
  box-shadow: rgba(248, 83, 189, 0.6) 0px 0px 6px 1px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
display: flex;
font-size: 14px;
font-weight: 900;
height: 41px;
letter-spacing: 0.14px;
line-height: initial;
margin-right: 10.01px;
padding-bottom: 10.01px;
padding-left: 17.5px;
padding-right: 17.5px;
padding-top: 10.01px;
position: relative;
text-align: left;
}

.bg-dark {
  background-color: rgb(30, 32, 41) !important;
}

.pagination-btn {
  cursor:pointer;
}

.disabled-pagination-btn{
  cursor:initial;
}

.pagination-btn:hover {
  color: rgb(245, 86, 159);
}
html {
  background-color: #f7f8f9
}

.gradientText {
  background-image: linear-gradient(54deg, #f853bd, rgba(248, 83, 189, 0.7) 99%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.row {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
  margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;
}



.rounded {
  border-radius: 6px !important;
}
.gradient-pink{
  background: #f853bd;
  // box-shadow: rgba(248, 83, 189, 0.6) 0px 0px 6px 1px;
  border: none;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  padding: 12px 25px;
  border-radius: 6px;
  font-size: 15px;
  height: 2.7rem;
}
.button-none {
  -webkit-appearance: none !important;
  padding: 0px;
  border:none;
  width: 100%;
  background: none;
}
.custom-select{
  font-size: 13px;
  color: #162638 !important;
}
.mt--1 {
  margin-top: -8px;
}
.offcanvas {
  visibility: inherit;
}
.offcanvas-sub {
  border-top: none !important;
  height: 100vh !important;
  padding: none;
  padding-top: 0px !important;
  // background-color: #f7f8f9 !important;
}
.styles-module_header__1aDvk {
  padding-top: 7vh !important;
}
.styles-module_title__2KezC {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'made_gentleregular';
}
.styles-module_body__2mo3X {
  padding: 0px !important;
}
.gradient-light{
  background: #f0f2f4;
  // box-shadow: rgba(248, 83, 189, 0.6) 0px 0px 6px 1px;
  border: none;
  font-weight: 600;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  padding: 12px 25px;
  border-radius: 6px;
  font-size: 15px;
  height: 2.7rem;
}

.mobile-btn{
  background: #f853bd;
  // box-shadow: rgba(248, 83, 189, 0.6) 0px 0px 6px 1px;
  border: none;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  padding: 12px 25px;
  border-radius: 8px;
  font-size: 17px;
  height: 2.9rem;
}


.menu-list{
  text-align: initial;
}

.dashed {
  border:2px dashed #f0f2f4 !important;
  border-radius: 8px;
}
.input-icon {
  position:absolute;
  margin-top:14px;
  margin-left:5px;
  font-size:15px;
}

.gradient-navy{
  background: #162638;
  // box-shadow: rgba(248, 83, 189, 0.6) 0px 0px 6px 1px;
  border: none;
  font-weight: bolder;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  padding: 12px 25px;
  border-radius: 6px;
  font-size: 15px;
  height: 2.7rem;
}
.play-btn {
    height: 78px;
    width: 78px;
    font-size: 30px;
    line-height: 76px;
    border-radius: 50%;
    display: inline-block;
    color: #162638;
}

.play-icon {
      position: absolute;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;
}

.play-btn:hover {
    color: #f853bd;
}

.video-play {
  padding: 20px;
  border-radius: 100%;
}


.shadow {
  box-shadow: 0 1px 4px rgb(240, 241, 244), 0 1px 1px rgb(240, 241, 244) !important;
}
.actionCard:hover {
  background-color: #eff2f4;
  transition: 0.4s;
}
.post-card-skeleton {
  background-color: #fff !important;
}


@media (min-width: 992px) {
  .post-card:hover {
    background-color: #eff2f4 !important;
    transition: 0.4s;
  }
}


.post-card-mobile {
  min-width: 100% !important;
}

.text-muted {
  color: #8492a6 !important; }

.nav-logo {
  height: auto;
  width: 120px;
}

.centered-vertically-30 {
  position: absolute !important;
  top: 30% !important;
  transform: translate(0, -30%) !important;
}

.lp-nav {
  position: fixed;
  background-color: #fff;
  z-index: 999;
  width: 100%;
}

.creators-grid {
  display: flex;
  justify-content: space-between;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 12px;

}


.creator{
  border-radius: 8px;
  max-height: 280px;
  width: 100%;
}

.creator-info {
  margin-top: 8px;
}
.creator-img{
  height: 100%;
  width: 100%;
  max-height: 264px;
  border-radius: 8px;
  object-fit: cover;
    border-radius: 8px;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 100, 0, 1) 10%, rgb(0, 0, 0));
    transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.13, 1.02) 0s;
}

.icon-wrap{
  height:40px;
  width:40px;
  border-radius:8px;
  justify-content:center;
  font-size:24px;
}
.pink-box-shadow {
  box-shadow: rgba(248, 83, 189, .6) 0px 0px 6px 1px;
}
.waysToEarn {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
}
@media (max-width: 992px) {
  .banner-cta {
    text-align: center;
  }
  .mb-3-mobile {
    margin-bottom: 1rem !important;
  }
  .pb-0-mobile {
    padding-bottom: 0rem !important;
  }
  .waysToEarn {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px !important;

  }
  .fast-facts {
    display:flex;
    flex-direction: column !important;

  }

  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
      --bs-gutter-x: 1.1rem;
      --bs-gutter-y: 0;
      width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-right: auto;
      margin-left: auto;
  }
}


html {
  overflow-x: hidden;
}


.homeImg{
  margin-bottom: 5px;
  width: 35px;
  height:auto;
}

.logo-lg {
  margin-bottom: 0px;
}
.MuiBottomNavigationAction-root {
  color:#8492a6 !important;
}

.list-inline-item {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.border-bottom-dark {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: none !important;
}

.fw-bolder {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: 700 !important;
}
.fw-semi {
  font-weight: 600;
}
.fw-normal {
  font-weight: normal !important;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.jc-center {
  justify-content: center;
}


.jc-space-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

.al-center {
  align-items: center;
}

.border-none {
  border:none !important;
}
.styles-module_header__1aDvk {
  border-bottom: 1px solid #f0f2f4 !important;
}

.align-center {
  align-items: center;
}

  .modal-dialog {
    max-width: 600px;
    margin: 3.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } 

  .alert-pink {
    color: #f538bd;
  }

.MuiStepIcon-root {
  color: #e9ecf2 !important;
}

.MuiStepConnector-line {
  border-color: #e9ecf2 !important;
}

.MuiStepIcon-text {
  fill: #bec0c4 !important;
}

.MuiStepIcon-active .MuiStepIcon-text {
  fill: #fff !important;
}

.disabled {
  background-color: #e9ecf2 !important;
  color:#bec0c4 !important;
}

.option-active {
  background-color: #feddf2;
}

.option-button {
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 1px solid #e9ecf2;
}

.option-button:hover {
  background-color: #f7f8f9;
  transition: all .15s ease-in;
}

.option-active {
  border: 1px solid #f853bd !important;
}

.step-circle {
  width:20px;
  height:20px; 
  background:#162638;
  border-radius:100%; 
  color:#fff;
  justify-content:center;
}

.option-active:hover {
  background-color: #feddf2;
}



input[type='radio']:after {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #e9ecf2;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #f853bd;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #feddf2;
}


.radio-label {
  margin-left: 12px;
  font-size: 15px;
  margin-bottom: 0px;

}

.ml-2{
  margin-left: .5rem;
}

.br-0 {
  border-radius: 0px !important;
}

.mobileTabs {
  display: flex;
  flex-direction: row;
  margin-top:-22px;
  min-height:54px; 
  border-radius:0px
}

.fancyreport{
  background-image: linear-gradient(to right bottom, rgb(248, 127, 212), rgb(248, 83, 189));
}
// .fancyreport {
//   background-color: rgb(255, 255, 255);
// background-image: linear-gradient(to right bottom, rgb(248, 127, 212), rgb(248, 83, 189));
// border-bottom-color: rgb(229, 231, 235);
// border-bottom-left-radius: 8px;
// border-bottom-right-radius: 8px;
// border-bottom-style: solid;
// border-bottom-width: 0px;
// border-image-outset: 0;
// border-image-repeat: stretch;
// border-image-slice: 100%;
// border-image-source: none;
// border-image-width: 1;
// border-left-color: rgb(229, 231, 235);
// border-left-style: solid;
// border-left-width: 0px;
// border-right-color: rgb(229, 231, 235);
// border-right-style: solid;
// border-right-width: 0px;
// border-top-color: rgb(229, 231, 235);
// border-top-left-radius: 8px;
// border-top-right-radius: 8px;
// border-top-style: solid;
// border-top-width: 0px;
// box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
// box-sizing: border-box;
// color: rgb(100, 116, 139);
// display: flex;
// flex-direction: column;
// font-family: Poppins, ui-sans-serif, system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// font-feature-settings: normal;
// font-size: 14px;
// font-variation-settings: normal;
// height: 164px;
// line-height: 20px;
// min-width: 1px;
// overflow-wrap: break-word;
// padding-bottom: 16px;
// padding-left: 20px;
// padding-right: 20px;
// position: relative;
// tab-size: 4;
// text-rendering: optimizelegibility;
// width: 711px;

// }

.btn-light:hover {
  background: rgba(57,76,96,0.15);
  border-color: rgba(57, 76, 96, 0.15) !important;
}
.btn-light {
  color: #162638;
  font-weight: 600;
}
.btn-light:hover {
  color:#162638;
}
.shadow-none {
  box-shadow: none !important;
}

.accordion-item {
  border-color: #f0f2f4 !important;
}

.flex-col {
  flex-direction: column;
}


.home-search{
	padding:0;
	position: unset;
	border-radius:$border-radius;
	overflow: hidden;
		
	input[type=search]{
		padding: 0px 80px 0px 25px;
		border-radius: inherit;
		font-size: 14px;
	}
	i{
		font-size: 15px;
		right: 15px;
		left: auto !important;
		color:#b9b9b9;
		&:after{
			color:inherit;
		}
	}
	
	.if-not-aurora{
		left: auto !important;
		right: 0;
		transform: rotate(180deg) scale(1) !important;
		color: #b9b9b9;
	}
	.searchbar{
		margin: 0 !important;
		box-shadow: none !important;
	
		.input-clear-button:after {
			line-height: 50px;
			content: 'delete_md';
			opacity: 1;
			margin-left: -40px;
			font-size: 20px;
			color: #b9b9b9;
		}
	}
}
@media (max-width: 992px) {
  .paymentsTitle {
    max-width: 250px;
  }
}

.jobs-alerts{
	display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
		
	.info{
		.title{
			font-size:18px;
			margin-top:0;
			margin-bottom:5px;
			color:#fff;
			font-weight: 600;
      
		}
		p{
			font-size: 11.5px;
			color: #fff;
			font-weight: 400;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.media{
		width: 95px;
		margin-top: -40px;
		border-radius: 46px;
		min-width: 95px;
		margin-right: -10px;
		margin-bottom: -10px;
		
		img{
			width:100%;
		}
	}
}
.card-bx{
	box-shadow:0 8px 12px 0 rgba(0,0,0,0.03);
	border-radius:$border-radius;
	
	&.bg-primary{
		box-shadow:0 8px 12px 0 rgba($primary,0.1);
	}
}
.counter-box{
	.title{
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 5px;
		margin-top: 0;
	    line-height: 1.3;
		i{
			opacity:0.25;
		}		
	}
	p{
		font-size:14px;
		color:#696767;
		margin-top:0;
		margin-bottom:0;
	}
}


.title-bar{
	display:flex;
	align-items:center;
	justify-content:space-between;
	font-size:14px; 
	
	.dz-title{
		font-weight:600;
	}
}
.feature-jobe.demo-swiper-multiple,
.tag-swiper.demo-swiper-multiple{ 
	margin-right: -15px;
	height: auto;
	
	.swiper-slide{
		width: auto;
		border: 0;
		background: transparent;
		height: auto;
		display: block;
	}
}
.feature-jobe{
	margin-left: -8px;
}

.post-card{
    padding: 20px 20px;
    box-sizing: border-box;
    width:100%;
    border: 1px solid #f0f2f4!important;
    border-radius: 8px;
	
	.card-media{
		display:flex;
		align-items:center;
		
		.title{
			font-weight:500;
			font-size:14px;
			color:#696767;
		}
		.media{
			min-width:38px;
			width:38px;
			min-height:38px;
			height:38px;
			img{
				width:100%;
			}
		}
		.bookmark-btn{
			font-size: 16px;
			margin-left: auto;
			.checkmark:before{
				font-size: 15px;
			}
		}
	}
	.info{
		.title{
			font-size:17px;
			color:#162638;
			font-weight:600;
			margin-top:0;
			margin-bottom:3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 315px;
			a{
				color:#000;
			}
		}
		p{
			font-size:13px;
		    margin: 0;	
		}
	}
	.price{
		font-size: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 500;
		
		a{
			font-size:18px;
			color:#000;
			opacity:0.3;
		}
	}
}
.recent-jobs-list{

	ul{
		&:before,
		&:after{
			content:none;
		}
		.item-content{
			padding-left:30px;
			border-bottom:1px solid #eee;
			
			.item-media{
			    padding: 20px 0;
			}
			.item-inner{
				padding-right: 30px;
				padding-top: 20px;
				padding-bottom: 20px;
				
				&:after{
					content:none;
				}
				
				.item-title-row{
					.item-title{
						margin:0;
						font-size:16px;
						font-weight:500;
					}
					.bookmark-btn{
						width: 12px;
						height: 12px;
						color: #000;
						font-size: 18px;
							
						i{
							position:absolute;
						}
						.fas{
							display:none;
						}
						.far{
							color:#000;
						}
						&.active{
							.fa-bookmark{
								color:$warning;
								display:block;
							}
							.fa-bookmark-o{
								display:none;
							}
						}
						
					}
				}
				.item-subtitle{
					font-size:12px;
					font-weight:500;
					margin-bottom:10px;
				}
				.item-price{
					font-weight:600;
					font-size:14px;
				}
			}
		}
	}
	&.style-2{
		margin: 0;
		ul{
			background: none;
			li{
				background-color: #fff;
				border-radius: 8px;
				margin: 15px 0;
			}
			.item-content{
				padding: 12px;
				border: 0;
				.item-media{
					padding: 0;
					align-self: center;
					img{
						width: 100%;
						max-width: 50px;
					}
				}
				.item-inner{
					padding: 0;
					&:after{
						content:none;
					}
					.item-title-row{
						.item-title{
							font-size: 18px;
							font-weight: 600;
						}
					}
					.address-title{
						font-size:14px;
						font-weight:500;
						margin-bottom:5px;
					}
					.job-title{
						font-weight:600;
						font-size:14px;
					}
				}
			}
		}
	}	
}

// theme-color-list
.theme-color-list{
	padding: 15px 10px 10px;
    margin: 30px 0 0;
	
	li{
		display: inline-block;
		width: 33.33%;
		text-align: center;
		margin-bottom: 20px;
		
		.button{
			padding: 0;
			width: 40px;
			height: 40px;
			margin: 0 auto 5px;
			
			&.active:after{
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				font-weight: 700;
			}
		}
		span{
			color: #000;
			text-transform: capitalize;
			font-weight: 500;
			font-size: 14px;
			opacity: 0.9;
		}
	}
}
.theme-color-modal{
	border: 0;
}
.color-sheet{
	display: block;
    border-radius: 5px;
    background-color: #fff;
    padding: 0;
    margin-bottom: 0;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
	
	i{
		font-size: 16px;
		color: #000;
	}
}

// dark light
.dark-light{
	width: 35px;
    height: 35px;
    position: relative;
    margin: 0 0 0 10px;
    padding: 0;
    display: block;
	
	li{
		position: relative;
		&.demo-theme-picker{
			position: absolute;
			display: inline-block;
			padding: 0;
			border: 0;
			box-shadow: unset;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: 15px;
			color: #000;
			
			&:after{
				font-weight: 700;
				font-family: "Font Awesome 5 Free";
			}
			&.light:after{
				content: "\f185";
			}
			&.dark:after{
				content: "\f186";
			}
			&.active{
				display: none;
			}
		}
	}
}

// Card Swiper
.card-swiper{
    margin-left: -30px;
    padding-bottom: 25px;
}

/* Bookmark */
.bookmark-btn{
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: "Font Awesome 5 Free";
	
	.checkmark{
		font-weight: 900;
		line-height: 1;
	
		&:before{
			content: "\f001";
			font-size: 18px;
			color: rgb(132, 146, 166);
			font-weight: 500;
		}
	}
	
	input{
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		display: none;
		
		&:checked ~ .checkmark:before{
			color: #ff9838;
			font-weight: 700;
		}
	}
}

//RESUME
.resume-title{
	text-align: center;
}
.light-text{
	font-size: 14px;
	margin: 0;
}
.resume-area{
	text-align: center;
	padding: 30px 0;
	&:nth-child(2n){
		border-bottom: 1px solid #c0e3de;
	}
	img{
		width: 55px;
		min-width: 55px;
	}
	.title{
		margin:12px 0;
	}
	.button{
		margin: 15px 0;
	}
}

.circle-box{
	width: 90px;
	height: 90px;
	display: flex;
	background: #fbfbfb;
	justify-content: center;
	align-items: center;
	margin: auto;
	border-radius: 50%;
	box-shadow: 0 4px 18px -9px #a7c0f7;
	img{
		width: 55px;
		min-width: 55px;
	}
	&.style-2{
		width: 80px;
		height: 80px;
	}
	svg{
		width: 50px;
		height: 50px;
		path{
			fill: $primary;
		}
	}
}


.tag{
	font-size: 14px;
	padding: 18px 25px;
	text-transform: capitalize;
	font-weight: 500;
	height: auto;
  height: 55px;
  border-radius: 8px;
  color: #fff;
  background-color: #f853bd;
	line-height: 1.4;
}
.tag-input{
	background-color: #e9dbff;
	color: #000;
	padding: 10px 12px;
	border-radius: 8px;
	font-size: 0.875rem;
	display: inline-block;
	margin-right: 6px;
	margin-bottom: 10px;
	i{
		margin-right: 6px;
	}
}

.mb-15 {
  margin-bottom: 15px;
}
.mt-15{
  margin-top: 15px;
}

.ml-15{
  margin-left: 15px;
}

.mr-15{
  margin-right: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mt-10{
  margin-top: 10px;
}

.ml-10{
  margin-left: 10px;
}

.mr-10{
  margin-right: 10px;
}

.slider-grid {
  overflow-x: scroll;
}
.slider-grid::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.slider-grid {
  display: grid;
  column-gap: 11px;
  grid-template-columns: 1fr 1fr 1fr;
}

.acceptDecline {
  display: grid;
  column-gap: 11px;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1200px) {
  
  .slider-grid {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .slider-grid {
    display: grid;
    grid-template-columns: 1fr ;
  }
  // .slider-grid-mobile {
  //   display: flex;
  //   overflow-x: scroll;
  // }
  
  .modal-content {
    width: 95%;
    margin: auto;
  }
  
}



.fancy-select{
  min-width: 150px;
  border-color: #e5e7eb;
  cursor: pointer !important;
  height: 40px;
}

.cursor-pointer__control {
  cursor: pointer !important;
}

.css-1pahdxg-control:hover {
  border-color: #e5e7eb !important;
  box-shadow: none !important;
}
.cursor-pointer__option {
  cursor:pointer !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: #e5e7eb !important;
}

.css-yk16xz-control {
  border-color: #e5e7eb !important;
}

.cursor-pointer__option--is-focused {
  background-color: #e5e7eb !important;
}

.cursor-pointer__option--is-selected {
  cursor: pointer !important;
  background-color: #f857bd !important;
}



.pill-table {
  padding: 3px 5px;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}

.pill-pink {
  background-color: #feddf2;
  color: #f853bd;
}

.pill-blue {
  background-color: #b3e1f0;
  color: #009ccc!important;
}

.pill-green {
  background-color: rgba(40,199,111,.12)!important;
  color: #28c76f!important;
}

.alert-danger {
  background-color: rgb(254, 242, 242) !important;
  color: rgb(239, 68, 68) !important;
  border: none !important;
  
}
.minTableCell {
  min-width: 200px;
}
.alert-success {
  background-color:  rgb(221,243,228) !important;
  border: none !important;
  color: #22bb33 !important;
}
.alert-pink {
  padding: 16px;
  background-color: #feddf2;
  color: #f853bd;
  border: none !important;
}
.alert {
  text-align: start;
  border: 4px;
  display:  flex !important;
  align-items: center;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 500;
  i {
    margin-right: 16px;
    font-size: 22px;
  }
}
// .alert-success {
//   background-color: rgba(40,199,111,.12)!important;
//   color: #162638!important;
//   font-weight: bold;
//   border-color: rgba(40,199,111,.12) !important;
// }

.full-width {
  width: 100%;
}
/* common */
.ribbon {
  width: 140px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #f853bd;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: #f853bd;
  color: #fff;
  font: 700 11px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -2px;
  left: -15px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -15px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
.pullableDiv {
  zIndex:9999;
  top: 100px;
  position: relative;
  width: 100%;
}

.btn:disabled {
  border-color: transparent;
}

.table > :not(caption) > * > * {
  background-color: #fff !important;
}



.pb-100 {
  padding-bottom: 100px;
}

.exploreImg {
  width: 200px;
  object-fit: cover;
  border-radius: 6px 0px 0px 6px;
}

.exploreOpportunity { 
  display:flex;
  flex-direction:row;
}

@media (max-width: 992px) {
  .exploreOpportunity { 
    display:flex;
    flex-direction:column;
  }
  .exploreImg {
    width: 100px;
    height: 100px; 
    border-radius: 6px;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
  }
  
}