//
// right-sidebar.scss
//
.hide {
    display: none !important;
    animation: fadeOutLeft .2s;
}
.show {
    display: block !important;
}

@keyframes fadeInLeft {
    0% { right: -320px;
        display: none !important;  }
    100% { right: 0; display: block !important; }
}

@keyframes fadeOutLeft {
    0% { right: 0px;
        display: block !important;  }
    100% { right: -320px; display: none !important; }
}

@keyframes fadeInOverlay {
    0% { background-color: rgba($dark,0);}
    100% { background-color: rgba($dark,0.55); }
}
.right-bar.show {
    height: 100vh;
}
.right-bar {
    background-color: $card-bg;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    overflow-y: scroll;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    visibility: initial;
    float: right !important;
    right: 0;
    top: 0;
    bottom: 0;
    .right-bar-toggle {
        background-color: lighten($dark, 7%);
        height: 24px;
        width: 24px;
        line-height: 24px;
        color: $gray-200;
        text-align: center;
        border-radius: 50%;

        &:hover {
            background-color: lighten($dark, 10%);
        }
    }
}

// Rightbar overlay
.rightbar-overlay {
    position: fixed;
    left: 0;
    right: 0;
    background-color: rgba($dark,0.55);
    // animation: fadeInOverlay .2s;
    top: 0;
    transition: all 400ms ease-out;
    bottom: 0;
    display: none;
    z-index: 2;
}

.right-bar-enabled {
    .right-bar {
        right: 0;
    }
    .rightbar-overlay {
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}