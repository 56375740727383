.modal-title{
    width: 100%;
}
.MuiStep-horizontal {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.MuiTabs-indicator {
    background-color: #f853bd !important;
}