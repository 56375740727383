

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #f853bd !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
    color: #f853bd !important;
}
.MuiStepper-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.MuiStepLabel-label {
    font-family: 'madeGentle', sans-serif !important;
    font-size: 0.8rem !important;
    color: #bfc3c9 !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
    color: #162638 !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
    color: #162638 !important;
}

.MuiStepIcon-root {
    color: #bfc3c9 !important;

}

.title-sec{
    display:flex;
    align-items: center;
}

.title-text-sec {
    margin-left: 12px;
}

.cpCard{
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    img {
        height: 56px;
        width: 56px;
        
    }
}

.cpStuff {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        margin-bottom: 0px;
    }
    h6 {
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.offeredByWrapper {
    margin-left: 1rem;
}

@media screen and (max-width: 1000px) {
    .title-sec{
        display:flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .title-text-sec {
        margin-left: 0px;
    }

    .cpCard{
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        align-items: center;
        img {
            height: 65px;
            width: 65px;
            
        }
    }
    .cpStuff {
        flex-direction: column;
        text-align: center;
        
    }
    .offeredByWrapper {
        margin-left: 0px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
}