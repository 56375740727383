//
// Google font - IBM Plex Sans
//

@import url('https://fonts.googleapis.com/css2?family=madeGentle:wght@300;400;700;900');
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800,900,bold,bolder');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black:wght@300;400;500;600;700;900');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@300;400;500;600;700;900');

@font-face {
    font-family: 'made_gentleregular';
    src: url('made_gentle-webfont.woff2') format('woff2'),
         url('made_gentle-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}