// 
// _footer.scss
// 
.account-pages {
  margin-bottom: 6rem;
  min-height: 70vh;
}
.footer {
    position: relative;
    width: 100%;
    // bottom: -100px;
    bottom: 0;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #f8f9fa;
}

@media (max-width: 992px) {
  .footer {
    position: relative;
  }
}

.footer-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-logo-container {
    width: 120px;
    margin-right: 60px;
}
  
  .left-side-footer-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-line {
    width: 100%;
    height: 1px;
    margin-top: 22px;
    margin-bottom: 22px;
    background-color: #e5e5e5;
  }
  
  .footer-logo-container {
    width: 120px;
    margin-right: 60px;
  }
  
  .footer-link {
    margin-right: 12px;
    margin-left: 12px;
    font-weight: 500;
  }

  .footer-link {
    margin-right: 12px;
    margin-left: 12px;
    font-weight: 500;
  }
  
  .footer-link.first {
    margin-left: 0px;
  }

  
.footer-holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @media screen and (max-width: 991px) {
    .footer-holder {
        flex-direction: column;
        align-items: baseline;
      }
  }

  .footer {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 30px;
  }

  .footer-link {
    margin: 12px 0px;
    margin-right: 12px !important;
    -webkit-transition: color 350ms ease;
    transition: color 350ms ease;
    color: #162638;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
  }

  ._18px {
    font-size: 18px;
}

  .footer-link:hover{
    color:#f853bd;
  }


  
  .footer-link.first {
    margin-left: 0px;
  }
@media (max-width: 992px) {
    .hide-mobile {
        display: none;
    }
}

@media (min-width: 992px) {
    .hide-desktop {
        display: none;
    }
}