legend {
    font-size: 14px !important;
    font-weight: 600;
}

.form-check {
    color:#162638;
}

.form-check-input:checked {
    background-color: #f853bd !important;
    border-color: #f853bd !important;
}