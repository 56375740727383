// 
// _header.scss
// 

#page-topbar {
    top: 0;
    right: 0;
    min-height: 250px;
    left: $sidebar-width;
    z-index: 1002;
    background-color: #162638;
    // background-image: url('../../../images/header-texture.svg');
    background-size: contain;
    box-shadow: $box-shadow;
}

.navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 65px;

    .dropdown .show {
        &.header-item {
            background-color: $gray-100;
        }
    }

    
}

body {
    &.vertical-collpsed {
        #page-topbar {
            left: $sidebar-collapsed-width;
            @media (max-width: 991.98px){
                left: 0;
            }
        }
    }
    &:not(.vertical-collpsed) {
        .navbar-header {
            .vertical-menu-btn {
                display: none;
            }
        }

        .app-search {
            margin-left: 10px;
        }
    }
}

.navbar-brand-box {
    width: $navbar-brand-box-width;
    z-index: 1;
}

.logo {
    line-height: 70px;

}

.logo-dark {
    display: $display-block;
}



/* Search */

.app-search {
    padding: calc(#{$header-height - 38px} / 2) 0;

    .form-control {
        border: none;
        height: 38px;
        padding-left: 40px;
        padding-right: 20px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 5px;
    }
    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: 38px;
        left: 13px;
        top: 0;
        color: $gray-600;
    }
}

// Mega menu

.megamenu-list {
    li{
        position: relative;
        padding: 5px 0px;
        a{
            color: $dropdown-color;
        }
    }
}

@media (max-width: 991.98px) {
    .navbar-brand-box {
        width: auto;
    }

    #page-topbar {
        left: 0;
        background-size: cover;
        .navbar-header {
            padding-left: 10px;
            padding-right: 10px;
            .navbar-brand-box{
                display: inline-block;
                position: relative;
            }

            .vertical-menu-btn {
                display: inline-block;
            }
        }
    }

    .logo {
        span.logo-sm {
            display: inline-block;
        }
    }
}

.page-content {
    padding-bottom: 1rem;
    margin-top: 20px;
}

.dashboard-content {
    margin-top: 5px !important;
}

.header-item {
    height: $header-height;
    box-shadow: none !important;
    color: #bfc3c9;
    border: 0;
    border-radius: 0px;

    &:hover {
        color: $header-item-color;
    }
}

.header-profile-user {
    height: 33px;
    width: 33px;
    background-color: $gray-300;
    padding: 3px;
}
.subject {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.message {
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message p {
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.noti-icon {
    i {
        font-size: 25px;
        color: #162638;
    }

    .badge {
        position: absolute;
        top: 12px;
        right: 5px;
    }
}

.notification-item {
    .d-flex {
        padding: 0.75rem 1rem;
        display: flex;

        &:hover {
            background-color: $dropdown-link-hover-bg;
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $gray-600;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        border-color: $gray-200;
    }
}

// Full Screen
.fullscreen-enable {
    [data-bs-toggle="fullscreen"] {
        .uil-minus-path::before {
            content: '\eb8d';
        }
    }
}

body[data-topbar="dark"] {
    #page-topbar { 
        background-color: $header-dark-bg;
    }
    .navbar-header {
        .dropdown .show {
            &.header-item {
                background-color: rgba($white, 0.05);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .header-item {
        color: $header-dark-item-color;
    
        &:hover {
            color: $header-dark-item-color;
        }
    }

    .header-profile-user {
        background-color: rgba($white, 0.25);
    }
    
    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
    
        .form-control {
            background-color: lighten($header-dark-bg,4%);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white,0.5);
        }
    }
}

body[data-sidebar="dark"] {
    .navbar-brand-box {

        .logo-dark {
            display: none;
        }
    
        .logo-light {
            display: block;

        }
    }


}

@media (max-width: 600px) {
    .navbar-header {
        .dropdown {
            position: static;
            .dropdown-menu {
                left: unset !important;
                right: 10px !important;
            }
        }
    }
}


body[data-layout="horizontal"] {
    .page-content {
        padding: calc(30px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);

        @media (max-width: 991.98px) { 
            margin-top: 0px;
        }
    }    
}



@media (max-width: 374.99px) { 
    .navbar-brand-box {
        text-align: center;
    }

    .language-switch {
        display: none !important;
    }
}

.navbar-container {
    height: 64px;
    position: fixed;
    background: #fff;
    top: 0px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    z-index: 101;
}

.MuiButtonBase-root {
    align-items: start !important;
}

.MuiBottomNavigation-root {
    position:fixed !important;
    top:88% !important;
    height: 120px !important;
    width:100%;
    z-index: 1 !important;
}

@media (min-width: 992px){
    .MuiBottomNavigation-root {
        display: none !important;
    }
    
}

.MuiBottomNavigationAction-label {
    font-family: sans-serif !important;
}

.navbar-container-native {
    height: 104px;
    padding-top: 40px;
    position: fixed;
    background: #fff;
    top: 0px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    z-index: 101;
}
.MuiBottomNavigationAction-root.Mui-selected {
    color: #f853bd !important;
}

.iconBadge {
    height: 42px;
    width: 24px;
    border-radius: 100%;
    background: #fff;
    color: rgb(248, 83, 189);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    font-size: 28px;
}
.my-auto{
    display: flex;
    flex-direction: row;
}
.statistics-body {
    padding-bottom: 5px;
}