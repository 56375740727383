.MuiTableCell-root {
    font-family: 'Inter', sans-serif !important;
    color: #162638 !important;
    border-color: #dee2e6 !important;
    font-size: 16px !important;
}

.MuiTableCell-stickyHeader {
    background-color: #fff !important;
    font-weight: bold !important;
}


.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #f7f8f9 !important;
}

@media (max-width: 992px) {
    .mobile-creator-col {
        width: 165px;
        max-width: 165px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 3px;
    }
}
@media (max-width: 480px) {
    .MuiTablePagination-selectRoot {
        margin-left: 0px !important;
        margin-right: 14px !important;
    }
    .platform-img {
        display: none !important;
    }
    .mobile-creator-col {
        width: 100px;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 3px;
    }

}

#likes-tip{
    max-width: 55px;
}


@media (max-width: 992px) {
    
    .platform-img {
        display: none !important;
    }
   

}
.MuiTablePagination-actions {
    margin-left: 5px !important;
}

.modal-title{
    font-weight: 700;
    letter-spacing:-.5px;
}

.center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tooltip.show{
    opacity: 1;
}
.tooltip-inner {
    background-color: #162638;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #162638 !important;
}


.form-group {
    margin-bottom: 20px;
    label {
        font-size: 14px;
        font-weight: 600;
    }
    .form-control {
        box-shadow: none;
        background-color: #fff;
        border-radius: 4 !important;
        color: #000;
        height: 42px; 
        font-size: 13px;
        min-height: 50px;
        font-size: 16px;
        transition: all 0.5s ease;
        
    } 

    .form-group .form-control:focus {
        box-shadow: 0 0 0 0.15rem rgba(248, 83, 189, .25);
    }
    textarea {
        height: 120px !important;
        &.form-control {
            line-height: 25px;
        }
    }
    .icons {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 100px;
        font-size: 18px;
    }
}

.MuiPaper-elevation1 {
    box-shadow: none!important;

}

.MuiTableCell-stickyHeader {
    background-color: #fff !important;
    font-weight: bold !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #f7f8f9 !important;
}

@media (max-width: 992px) {
    .mobile-creator-col {
        width: 165px;
        max-width: 165px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 3px;
    }
}
@media (max-width: 480px) {
    .MuiTablePagination-selectRoot {
        margin-left: 0px !important;
        margin-right: 14px !important;
    }
    .platform-img {
        display: none !important;
    }
    .mobile-creator-col {
        width: 100px;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 3px;
    }

}

#likes-tip{
    max-width: 55px;
}


@media (max-width: 992px) {
    
    .platform-img {
        display: none !important;
    }
   

}
.MuiTablePagination-actions {
    margin-left: 5px !important;
}

.modal-title{
    font-weight: 700;
    letter-spacing:-.5px;
}

.center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tooltip.show{
    opacity: 1;
}
.tooltip-inner {
    background-color: #162638;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #162638 !important;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0px 0 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}


.MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
}

.MuiPaper-rounded {
    box-shadow: none !important;
}