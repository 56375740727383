// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  // box-shadow: 0 1px 4px rgb(240, 241, 244), 0 1px 1px rgb(240, 241, 244) !important;
  border: 1px solid #f0f2f4!important;
}


.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

@include media-breakpoint-down(lg) {
  .title-h2 {
    font-size: 28px !important;
  }
  .title-h4 {
    font-size: 26px !important;
  }
}



