
//
// accordion.scss
//

.accordion-button:not(.collapsed) {
    color: #f853bd;
    background-color: #fff;
}
.accordion-button {
    outline: none !important;
    border-bottom: none !important;
    font-weight: 600;
    
}
.collapse.show {
    border-top: 1px solid #f0f2f4 !important;

}
.custom-accordion {

    .card {
        + .card {
            margin-top: 0.5rem;
        }
    }

    a {

        &.collapsed {
            i.accor-down-icon {
                &:before {
                    content: "\F0140";
                }
            }
        }
    }

    .card-body {
        color: $text-muted;
    }
}