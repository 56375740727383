 
// Page-title
// 

.page-title-box {
    padding-bottom: .5rem;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        font-weight: normal;
        font-size: 1.3rem !important;
    }

    .page-title-right{
        @media (max-width: 575.98px) {
            display: none;
        }
    }
}
.actionCard{
    min-width: 110px;
    width: 100%;
    height: 100px;
    
}
html {
    background: rgb(30, 32, 41)
}